import { useState } from "react";
import axios from "axios";
import "./Search.css";
import Card from "react-bootstrap/Card";
import ConfigData from "../../config.json";
import noImage from "../../assets/images/certificate_default.jpg";
import ClickableDiv from "react-clickable-div";
import QrReader from "modern-react-qr-reader";
import "react-clickable-div/dist/index.css";

import SHRMLogo from "../../assets/images/SHRMLogo.png";
import FacebookIcon from "../../assets/icons/facebook.png";
import LinkedInIcon from "../../assets/icons/linkedin.png";
import TwitterIcon from "../../assets/icons/twitter.png";
import InstagramIcon from "../../assets/icons/instagram.png";
import YoutubeIcon from "../../assets/icons/youtube.png";

const Search = () => {
  const [result, setResult] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const handleOnChange = (event) => {
    setSearchValue(event.target.value);
  };

  const makeApiCall = async (searchInput) => {
    const trimmedSearchInput = searchInput.trim();
    var searchUrl = `${ConfigData.API_URL}/candidate`;
    try {
      const response = await axios.get(searchUrl, {
        params: { query: trimmedSearchInput },
      });
      setCandidates(response.data.candidates);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    let searchMHRSDUrl = `${ConfigData.API_URL}/mhrsd/candidates`;
    try {
      const response = await axios.get(searchMHRSDUrl, {
        params: { query: trimmedSearchInput },
      });

      setCandidates((prevCandidates) => [
        ...prevCandidates,
        ...response.data.candidates,
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOldCertificate = async (studentId, certificateNo) => {
    var searchUrl = `${ConfigData.API_URL}/candidate`;
    try {
      const {
        data: { candidates },
      } = await axios.get(searchUrl, {
        params: { query: studentId },
      });
      const filterCertificate = candidates.find(
        (cand) => cand.certNo === certificateNo
      );
      setCandidates(filterCertificate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = () => {
    setShowScanner(false);
    makeApiCall(searchValue);
  };

  const showQrCodeScanner = () => {
    setCandidates([]);
    setSearchValue("");
    setShowScanner((prevShowScanner) => !prevShowScanner);
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      if (data.includes("http")) {
        // This is new certificate
        window.open(data);
      } else {
        const certInfo = JSON.parse(data);
        if (certInfo && certInfo.StudentId) {
          setShowScanner(false);
          handleOldCertificate(certInfo.StudentId, certInfo.CertificateNumber);
        }
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      makeApiCall(e.target.value);
    }
  };

  console.log("----------->", candidates);

  return (
    <div className="page-container">
      <header class="page-header">
        <img src={SHRMLogo} alt="SHRM" class="page-header-logo" />
      </header>

      <div className="page-content">
        <div className="main">
          <div className="input-group">
            <input
              type="text"
              onKeyDown={(event) => keyPress(event)}
              className="form-control search-input"
              onChange={(event) => handleOnChange(event)}
              value={searchValue}
              placeholder="Please enter Email or Student ID to search."
            />
            <div className="input-group-append">
              <button
                onClick={handleSearch}
                className="btn btn-secondary mr-1"
                type="button"
              >
                <i className="fa fa-search"></i>
              </button>
              <button
                onClick={showQrCodeScanner}
                className="btn btn-secondary ml-1"
                type="button"
              >
                <i className="fa fa-qrcode"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          {showScanner && (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              facingMode={"environment"}
              style={{ width: "50%" }}
            />
          )}
        </div>

        <div>
          {candidates.length != 0 ? (
            <div className="container d-flex flex-wrap ">
              {candidates.map((candidate, index) => (
                <div key={index} className="mt-3 mb-3">
                  <div className="card">
                    <div className="row no-gutters">
                      <div
                        className="pdf-thumbnail-wrapper my-awesome-div transparent-button"
                        onClick={() => {
                          window
                            .open(
                              `${
                                candidate.imageUrl ||
                                candidate["certificates"]["v1"][
                                  "certificatePDFUrl"
                                ]
                              }${ConfigData.AZURE_BLOB_SAS_URL}`,
                              "_blank"
                            )
                            .focus();
                        }}
                      >
                        <object
                          data={`${
                            candidate.imageUrl ||
                            candidate["certificates"]["v1"]["certificatePDFUrl"]
                          }${ConfigData.AZURE_BLOB_SAS_URL}`}
                          width="100%"
                          height="100%"
                          className="pdf-thumbmail"
                          type="application/pdf"
                        >
                          <button
                            style={{
                              color: "white",
                              background: "#0076be",
                              width: "auto",
                              padding: "2px 4px",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              overflow: "hidden",
                              border: "none",
                              cursor: "pointer",
                              display: "inline-block",
                            }}
                            onClick={() => {
                              window
                                .open(
                                  `${
                                    candidate.imageUrl ||
                                    candidate["certificates"]["v1"][
                                      "certificatePDFUrl"
                                    ]
                                  }${ConfigData.AZURE_BLOB_SAS_URL}`,
                                  "_blank"
                                )
                                .focus();
                            }}
                          >
                            Get Certificate
                          </button>
                        </object>
                      </div>
                      <div className="col-sm-7">
                        <div className="card-body pt-0">
                          <h5 className="card-title">{candidate.name}</h5>
                          <p className="card-text">
                            Student ID: {candidate.studentId}
                          </p>
                          <p className="card-text">
                            {" "}
                            Program Name:{" "}
                            {candidate.subject || candidate.course}
                          </p>
                          <p className="card-text">
                            {" "}
                            Date of completion: {candidate.startDate}
                          </p>
                          <p className="card-text">
                            {" "}
                            Date of certificate issued: {candidate.endDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : !showScanner ? (
            <p className="no-resultp">No result found</p>
          ) : null}
        </div>
      </div>
      <footer className="footer text-white py-2">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0 p-0">
              <div className="d-flex">
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.facebook.com/SHRMMENA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={FacebookIcon}
                      alt="facebook"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.linkedin.com/company/shrm-mea"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={LinkedInIcon}
                      alt="linkedin"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://twitter.com/SHRMMEA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={TwitterIcon}
                      alt="twitter"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.instagram.com/shrmmena/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={InstagramIcon}
                      alt="instagram"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.youtube.com/channel/UCB1w7_o1vw-5K7OjXpwjTMw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={YoutubeIcon}
                      alt="youtube"
                    />
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center text-md-right p-0">
              <p className="mb-0 footer-line">
                Email:{" "}
                <a
                  href="mailto:SHRM.MEA@shrm.org"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  SHRM.MEA@shrm.org
                </a>{" "}
                | Landline:{" "}
                <a
                  href="tel:+97143649464"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  +971 43649464
                </a>{" "}
                |{" "}
                <span className="d-inline-block">
                  Copyright &copy; 2024 SHRM. All Rights Reserved.
                </span>{" "}
                |{" "}
                <a
                  href="https://www.shrm.org/about/privacy-policy"
                  className="text-white text-decoration-none"
                  target="_blank"
                  style={{ paddingRight: "2px" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Search;
