import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ConfigData from "../../config.json";
import noImage from "../../assets/images/certificate_default.jpg";
import "./DownloadCertificate.css";

import SHRMLogo from "../../assets/images/SHRMLogo.png";
import FacebookIcon from "../../assets/icons/facebook.png";
import LinkedInIcon from "../../assets/icons/linkedin.png";
import TwitterIcon from "../../assets/icons/twitter.png";
import InstagramIcon from "../../assets/icons/instagram.png";
import YoutubeIcon from "../../assets/icons/youtube.png";

const DownloadCertificate = () => {
  const { candidateId, certId } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const fetchFirst = fetch(
          `${ConfigData.API_URL}/candidates/${candidateId}/certificates/${certId}`
        );
        const fetchSecond = fetch(
          `${ConfigData.API_URL}/mhrsd/candidates/${candidateId}/certificates/${certId}`
        );

        const results = await Promise.allSettled([fetchFirst, fetchSecond]);

        // Check which promise succeeded
        const successfulResponse = results.find(
          (result) => result.status === "fulfilled" && result.value.ok
        );

        if (successfulResponse) {
          const data = await successfulResponse.value.json();
          setCertificate(data);
        } else {
          throw new Error("Network response was not ok");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, [certId, candidateId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!certificate) {
    return <div>No certificate found</div>;
  }

  return (
    <div className="page-container">
      <header class="page-header">
        <img src={SHRMLogo} alt="SHRM" class="page-header-logo" />
      </header>
      <div className="page-content">
        <div className="main">
          <div className="card">
            <div className="row no-gutters">
              <div
                onClick={() => {
                  window
                    .open(
                      `${
                        certificate.imageUrl ||
                        certificate["certificates"]["v1"]["certificatePDFUrl"]
                      }${ConfigData.AZURE_BLOB_SAS_URL}`,
                      "_blank"
                    )
                    .focus();
                }}
                className="col-sm-5 my-awesome-div transparent-button"
                style={{ margin: "auto" }}
              >
                {/* Use a default image if no certificate image URL is provided */}
                <object
                  data={`${
                    certificate.imageUrl ||
                    certificate["certificates"]["v1"]["certificatePDFUrl"]
                  }${ConfigData.AZURE_BLOB_SAS_URL}`}
                  width="100%"
                  height="202px"
                  style={{
                    pointerEvents: "none",
                    border: "none",
                  }}
                  type="application/pdf"
                >
                  {/* <p>
                Please download the certificate from{" "}
                <a
                  href={`${certificate.imageUrl}${ConfigData.AZURE_BLOB_SAS_URL}`}
                >
                  here
                </a>
              </p> */}
                  <button
                    style={{
                      color: "white",
                      background: "#0076be",
                      width: "auto",
                      padding: "2px 4px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      border: "none",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => {
                      window
                        .open(
                          `${
                            certificate.imageUrl ||
                            certificate["certificates"]["v1"][
                              "certificatePDFUrl"
                            ]
                          }${ConfigData.AZURE_BLOB_SAS_URL}`,
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Get Certificate
                  </button>
                </object>
              </div>
              <div className="col-sm-7">
                <div className="card-body">
                  <h5 className="card-title">{certificate.name}</h5>
                  <p className="card-text">
                    Student ID: {certificate.studentId}
                  </p>
                  <p className="card-text">
                    Program Name: {certificate.subject || certificate.course}
                  </p>
                  <p className="card-text">
                    Date of completion: {certificate.startDate}
                  </p>
                  <p className="card-text">
                    Date of certificate issued: {certificate.endDate}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer text-white py-2">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0 p-0">
              <div className="d-flex">
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.facebook.com/SHRMMENA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={FacebookIcon}
                      alt="facebook"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.linkedin.com/company/shrm-mea"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={LinkedInIcon}
                      alt="linkedin"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://twitter.com/SHRMMEA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={TwitterIcon}
                      alt="twitter"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.instagram.com/shrmmena/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={InstagramIcon}
                      alt="instagram"
                    />
                  </a>
                </span>
                <span className="icon-wrapper mx-2">
                  <a
                    href="https://www.youtube.com/channel/UCB1w7_o1vw-5K7OjXpwjTMw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="social-icon"
                      src={YoutubeIcon}
                      alt="youtube"
                    />
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center text-md-right p-0">
              <p className="mb-0 footer-line">
                Email:{" "}
                <a
                  href="mailto:SHRM.MEA@shrm.org"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  SHRM.MEA@shrm.org
                </a>{" "}
                | Landline:{" "}
                <a
                  href="tel:+97143649464"
                  className="text-white text-decoration-none"
                  // target="_blank"
                >
                  +971 43649464
                </a>{" "}
                |{" "}
                <span className="d-inline-block">
                  Copyright &copy; 2024 SHRM. All Rights Reserved.
                </span>{" "}
                |{" "}
                <a
                  href="https://www.shrm.org/about/privacy-policy"
                  className="text-white text-decoration-none"
                  target="_blank"
                  style={{ paddingRight: "2px" }}
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DownloadCertificate;
